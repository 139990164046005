import React from 'react'
import './OfkWorkout.scss'
import HeroBanner from '../../components/HeroBanner'
import Layout from '../../components/Layout'
import { graphql, navigate } from 'gatsby'
import { ListGroup, ListGroupItem } from 'reactstrap'

const OfkWorkout = ({ data }) => {
  const items = data.allWordpressWpOfkWorkout.edges.map(item => {
    return (
      <ListGroupItem
        key={item.node.id}
        onClick={() => navigate(`/ofkworkout/${item.node.slug}`)}
      >
        {item.node.title}
      </ListGroupItem>
    )
  })

  return (
    <Layout>
      <div className="OfkWorkout">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="OFK Workouts"
          caption="All the ObstacleFit Kids workouts we've got"
        />
        <div className="container content">
          <p>
            Missed a workout? Have a look below, find the day you missed and
            power through that workout!
          </p>
          <ListGroup>{items}</ListGroup>
        </div>
      </div>
    </Layout>
  )
}

export default OfkWorkout

export const query = graphql`
  query {
    allWordpressWpOfkWorkout {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    file(url: { eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
